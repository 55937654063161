<template lang="html">
  <section class="document-view-small" v-if="dataContent">
    <a
      :href="dataContent.file[0].url"
        download=""
        target="_blank"
        title="Descargar documento"
      class="content-new"
    >
      <div class="info-box">
        <p class="title">{{ dataContent.name }}</p>
        <!-- <p>
       {{ dataContent.header }}
        </p> -->
      </div>
    </a>
  </section>
</template>

<script lang="js">
    export default {
        name: 'document-view-small',
        props: ['dataContent'],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";
    .document-view-small {
        border-bottom: 1px solid $blue;
        padding: 10px 0 20px 0;
        &:last-of-type {
            border-bottom: 0;
        }
        .content-new {
            text-decoration: none;
        }
        .title {
            margin-bottom: 9px;
            font-size: 20px;
        }
    }
</style>