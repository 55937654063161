<template lang="html">
    <section class="content-footer-dinamic">
        <section class="grid-3" >
            <div class="grid" v-if="events">
                <p class="title-dest">Agenda</p>
                <div class="box-blue-round">
                    <!-- {{events}} -->
                    <template v-for="(event, key, index) in events" :key="key">
                        <smallEvent v-if="index >= 0 && index < 2" :dataContent="event"></smallEvent>
                    </template>
                </div>
                <router-link :to="{ name: 'events', query: { theme: themeBySlug.slug } }" class="link-arrow">Ver agenda</router-link>
            </div>
            <div class="grid" v-if="medias">

                <p class="title-dest">Imago CERMI</p>

                <div class="box-blue-round">
                    <sliderVideo :dataContent="Object.fromEntries(videos)"></sliderVideo>
                </div>
                <router-link :to="{name:'audiovisuals', query: { theme: themeBySlug.slug } }" class="link-arrow">Ver multimedia</router-link>
            </div>
            <div class="grid" v-if="files">
                <p class="title-dest">Documentos</p>
                <div class="box-blue-round">
                    <template v-for="(document, index) in Object.values(files).slice(0,3)" :key="index">
                        <documentSmall :dataContent="document"></documentSmall>
                    </template>


                </div>
                <router-link :to="{name:'documents', query: { theme: themeBySlug.slug } }" class="link-arrow">Ver documentos</router-link>
            </div>
        </section>
    </section>
</template>

<script lang="js">
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import store from '@/store';
    import utils from "@/utils/utils.js";
    import smallEvent from "@/components/events/view-small-event.vue";
    import sliderVideo from "@/components/videos/slider-videos.vue";
    import documentSmall from "@/components/documents/document-view-small.vue";
    export default {
        name: 'content-footer-dinamic',
        props: ['slug'],
        mounted(){
            this.loadEvents({
                page: this.page,
                length: 9,
                theme: this.theme,
                zone: this.zone
            })
            this.loadMedias({
                page: this.page,
                length: 12,
                theme: this.theme,
                zone: this.zone
            });
            this.loadFiles({
                page: this.page,
                length: 12,
                theme: this.theme,
                zone: this.zone
            });
        },
        data() {
            return {

            }
        },
        components: {
            smallEvent,
            sliderVideo,
            documentSmall,
        },
        methods: {
            ...mapActions({
                loadEvents: 'contents/loadEvents',
                loadMedias: "contents/loadMedias",
                loadFiles: 'contents/loadFiles'
            }),
            ...mapMutations({
                setPageEvents: 'contents/setPageEvents',
                setPageMedias: "contents/setPageMedias",
                setPageFile: 'contents/setPageFile',

            }),
            changePages(item) {
                this.setPageEvents(item)
                this.loadEvents({
                    page: this.page,
                });
                this.setPageMedias(item);
                this.loadMedias({
                    page: this.page
                });
                this.setPageFile(item)
                this.loadFiles({
                    page: this.page,
                });
                utils.LoaderScrollToTop();

            },
        },
        computed: {
            ...mapGetters({
                getMediaVideos: "contents/getMediaVideos",
                getFilesDocument: 'contents/getFilesDocument',
                page: 'contents/getNewViewPage'
            }),
            themeBySlug: function () {
                return store.getters['contents/getThemeSlug'](this.slug);
            },
            events: function () {
                return store.getters['contents/getEvents'](this.page);
            },
            medias: function () {
                return store.getters["contents/getMedias"](this.page);
            },
            videos() {
                return this.getMediaVideos(this.page);
            },
            files: function () {
                return store.getters['contents/getFiles'](this.page)
            },
            documents() {
                return this.getFilesDocument(this.page);
            },
        }
    }
</script>

<style scoped lang="scss">
    .content-footer-dinamic {
        .grid-3 {
            justify-content: center;
            border-top: 1px solid rgba(0, 105, 180, 0.08);
            padding: 40px 0;
        }

    }
</style>