<template lang="html">
  <section class="view-small-event" v-if="dataContent">
    <router-link
      :to="{
        name: 'page-entry',
        params: {
          typeContent: 'event',
          slug: this.dataContent.slug,
        },
      }"
      class="content-new"
      title="Enlace a evento"
    >
      <img
        :src="dataContent.image.url"
        :alt="dataContent.image_footer"
        class="img-new"
      />

      <div class="info-box">
        <h2>
          <Markdown
            v-if="this.dataContent.name_markdown"
            class="title"
            :source="this.dataContent.name_markdown"
          />
        </h2>

        <p class="box-dates">
          <span class="date-text">
            {{ currentDateTime(dataContent.start_date) }}</span
          >
          <span class="author" v-if="dataContent.source">{{
            dataContent.source
          }}</span>

          <template v-for="theme in dataContent.themes" :key="theme.id">
            <span class="category-tag">{{ theme.name }}</span>
          </template>
          <span class="category-location" v-if="dataContent.zone">{{
            dataContent.zone.name
          }}</span>
        </p>
      </div>
    </router-link>
  </section>
</template>

<script lang="js">
import moment from 'moment'
import Markdown from 'vue3-markdown-it';
export default {
    name: 'view-small-event',
    props: ['dataContent'],
    components: {
        Markdown
    },
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {
        currentDateTime(date) {
            return moment(date).locale('es').format('L')
        },
    },
    computed: {
        typeContent() {
            switch (this.dataContent.type) {
                case 'entry':
                    return 'noticia'
                case 'opinion':
                    return 'opinion'
                case 'interview':
                    return 'entrevista'
                case 'coverage':
                    return 'reportaje'
                case 'event':
                    return 'evento'
                case 'media':
                    return 'multimedia'
                default:
                    return 'noticia'
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

.view-small-event {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  .content-new {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    /* max-height: 300px; */
    position: relative;
    border-radius: 13px;
    padding-left: 10px;

    .img-new {
      width: 100px;
      height: 100px;
      border-radius: 13px;
      object-fit: cover;
      object-position: center;
    }

    .info-box {
      width: calc(100% - 100px);
      padding: 0 20px;

      .title {
        margin-bottom: 5px;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .view-small-event .content-new .img-new {
    width: 100%;
    margin-bottom: 10px;
  }

  .view-small-event .content-new .info-box {
    width: 100%;
    padding: 0;
  }

  .view-small-event .content-new .info-box .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 845px) {
  .view-small-event .content-new .img-new[data-v-2f248682] {
    height: 180px;
  }
}
</style>
