<template lang="html">

    <section class="slider-videos" v-if="dataContent">

        <agile :options="myOptions" ref="carousel">
            <template v-for="(video, key) in dataContent" :key="key">
                <!-- {{video.name}} -->
                <router-link :to="{
                        name: 'page-entry',
                        params: {
                           typeContent: 'media',
                          slug: video.slug,
                        },
                      }" class="content-new " title="Enlace al contenido">
                    <div class="div-img-video">
                        <img :src="video.image.url" :alt="video.image_footer" class="img-new" />
                    </div>

                    <!-- <videoItem :dataContent="video"></videoItem> -->
                </router-link>
            </template>
        </agile>
    </section>

</template>

<script lang="js">
    import {
        VueAgile
    } from "vue-agile";
    // import videoItem from "@/components/videos/video.vue";
    export default {
        name: 'slider-videos',
        props: ['dataContent'],
        mounted() {
            document.querySelector('.agile__actions .agile__nav-button--next').setAttribute('title', 'Pasar siguiente slider')
            document.querySelector('.agile__actions .agile__nav-button--prev').setAttribute('title', 'Pasar anterior slider')
        },
        components: {
            agile: VueAgile
            // videoItem
        },
        data: () => ({
            myOptions: {

                responsive: [{
                    breakpoint: 0,
                    settings: {
                        fade: false,
                        navButtons: true,
                        slidesToShow: 1,
                        dots: false,
                    },
                }],
            },
        }),
        methods: {

        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">
    .div-img-video {
        width: 100%;
        height: 205px;
        overflow: hidden;

    }

    .div-img-video img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
</style>